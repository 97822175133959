import Vue from 'vue'
import Vuex from 'vuex'
console.log()

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    addRoutes: [],
    routes: [],
    homeMenu: 'index'
  },
  mutations: {
    SET_MENU: (state, menu) => {
      state.homeMenu = menu;
    }
  },
  actions: {

  },
  modules: {
  }
})
