import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { MessageBox } from 'element-ui';

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const baseRoutes = [
  {
    path: '/autologin',
    name: 'autologin',
    component: () => import('../views/User/AutoLogin.vue'),
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/User/Index.vue'),
    children: [{
      path: '/user/login',
      name: 'login',
      component: () => import('../views/User/Login.vue'),
    }, {
      path: '/user/forget',
      name: 'forget',
      component: () => import('../views/User/Forget.vue'),
    }, {
      path: '/user/password',
      name: 'forget',
      component: () => import('../views/User/Password.vue'),
    }],
  },
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    children: [{
      path: '/index',
      name: 'index',
      component: () => import('@/views/Welcome.vue'),
    }, {
      path: '/chart_test',
      name: 'chart_test',
      component: () => import('@/views/Charts.vue'),
    }, {
      path: '/chart',
      name: 'chart',
      component: () => import('@/views/Charts.vue'),
    }, {
      path: '/chart2',
      name: 'chart2',
      component: () => import('@/views/Charts2.vue'),
    },{
      path: '/inspect',
      name: 'new_inspect',
      component: () => import('@/views/Inspect/Index.vue'),
    }, {
      path: '/inspect/create',
      name: 'creat_inspect',
      component: () => import('@/views/Inspect/Create.vue'),
    }, {
      path: '/inspect/city_create',
      name: 'creat_inspect',
      component: () => import('@/views/Inspect/Create.vue'),
    }, {
      path: '/inspect/province_create',
      name: 'province_inspect',
      component: () => import('@/views/Inspect/Create.vue'),
    }, {
      path: '/inspect/country_create',
      name: 'country_create',
      component: () => import('@/views/Inspect/Create.vue'),
    }, {
      path: '/table/:type',
      name: 'table',
      component: () => import('@/views/Table.vue'),
    }, {
      path: '/organization/:type',
      name: 'organization',
      component: () => import('@/views/Organization/Index.vue'),
    }, {
      path: '/organization/user/:type',
      name: 'organization_user',
      component: () => import('@/views/Organization/User.vue'),
    }, {
      path: '/organization/admin/:type',
      name: 'organization_admin',
      component: () => import('@/views/Organization/Admin.vue'),
    }, {
      path: '/question',
      name: 'question',
      component: () => import('@/views/Inspect/Question.vue'),
    }, {
      path: '/report',
      name: 'report',
      component: () => import('@/views/Report.vue'),
    }, {
      path: '/questionnaire',
      name: 'questionnaire',
      component: () => import('@/views/Questionnaire.vue'),
    }, {
      path: '/report',
      name: 'report',
      component: () => import('@/views/Report.vue'),
    }, {
      path: '/user_organ/:type',
      name: 'login',
      component: () => import('../views/User/Organization.vue'),
    }, {
      path: '/bind_mobile',
      name: 'Mobile',
      component: () => import('../views/User/Mobile.vue'),
    }],
  },
  {
    path: "*",
    name: "404",
    redirct: "/404",
    component: () => import("@/views/NotFound.vue"),
    hidden: true
  }
]

const router = new VueRouter({
  scrollBehavior: (to, from, savedPosition) => {
    window.scrollTo(0, 0);
  },
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: baseRoutes
});

function is_weixin() {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return true;
  } else {
    return false;
  }
}

router.beforeEach(async (to, from, next) => {
  if (is_weixin()) {
    MessageBox({
      title: '提示',
      center: true,
      dangerouslyUseHTMLString: true,
      message: '<p style="color: red">请不要使用微信浏览器进行评测。<br>建议您使用IE9及以上版本、Chrome、Firefox、Edge、360等主流浏览器浏览本网站。</p>'
    })
  }
  NProgress.start();
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');
  const is_change_pswd = localStorage.getItem('is_change_pswd');
  const is_organ_check = localStorage.getItem('is_organ_check');
  const is_bind_mobile = localStorage.getItem('is_bind_mobile');
  // console.log(to.path)
  if (token) {
    if (is_change_pswd == 0) {
      to.path === '/user/password' ? next() : next('/user/password');
      NProgress.done();
      return
    }
    // if(is_bind_mobile == 0 && role == 4) {
    //   to.path === '/bind_mobile' ? next() : next('/bind_mobile');
    //   NProgress.done();
    //   return
    // }
    if (is_organ_check == 0 && role == 4) {
      to.path === '/user_organ/set' ? next() : next('/user_organ/set');
      NProgress.done();
      return
    }
    if (to.path == '/' && role == 4) {
      next('/index')
    } else if (to.path == '/' && role == 3) {
      next('/table/city_review');
    } else if (to.path == '/' && role == 1) {
      next('/chart');
    } else if (to.path == '/' && role == 2) {
      next('/chart');
    } else {
      next()
    }
    NProgress.done();
  } else {
    if (to.path === '/user/forget') {
      next();
      NProgress.done();
    } else if (to.path === '/autologin') {
      next();
      NProgress.done();
    } else {
      to.path === '/user/login' ? next() : next('/user/login')
      NProgress.done();
    }
  }
})

// 


export { router, baseRoutes }
