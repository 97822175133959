<template>
<div id="app">
  <router-view />
</div>
</template>
<script>
  window.onload = function() {
    document.addEventListener('touchstart', function(event) {
      if (event.touches.length > 1) {
        event.preventDefault()
      }
    });
    document.addEventListener('gesturestart', function(event) {
      event.preventDefault()
    })
  };
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
}
ul,li{
  padding: 0;
  margin: 0;
  list-style: none;
}
h1,
h2,
h3,
h4,
p {
  margin: 0;
  text-align: left;
}

* {
  box-sizing: border-box;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.h20 {
  height: 20px;
}

.h30 {
  height: 30px;
}

@media only screen and (max-width: 1440px) {
  .mb5 {
    margin-bottom: 3.75px !important;
  }

  .mb10 {
    margin-bottom: 7.5px !important;
  }

  .h20 {
    height: 15px !important;
  }

  .h30 {
    height: 22.5px !important;
  }
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  // overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#footer {
  width: 100vw;
  height: 40px !important;
  line-height: 40px;
  // padding-bottom: 20px;
  background: #0083f5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
  // position: fixed;
  // left: 0;
  // bottom: 0;
  a{
    color: #fff;
    display: flex;
  align-items: center;
  }
  span {
    margin: 0 10px;
  }
}
</style>
